var homeBanner = new vidbg('#home-banner', {
    mp4: 'assets/media/mp4_video.mp4',
    webm: 'assets/media/webm_video.webm',
    poster: 'assets/images/home/home-banner.jpg',
},{
    autoplay:false,
    id:"vidbg",
    controls: false,
    loop: true,
    muted: true,
    playsInline: true
});

$(document).ready(() => {
    let playPause = 0;
    let videoBg = $('#vidbg');
    let playBtn = $('#play');
    playBtn.click(()=>{
    if(videoBg.get(0).paused){
        videoBg.get(0).play();
    }else{
        videoBg.get(0).pause();
    }});
    $('.popup-with-form').magnificPopup({
        type: 'inline',
        preloader: false,
        focus: '#forgotpass',

        // When elemened is focused, some mobile browsers in some cases zoom in
        // It looks not nice, so we disable it:
        callbacks: {
        beforeOpen: function() {
            if($(window).width() < 700) {
            this.st.focus = false;
            } else {
            this.st.focus = '#forgotpass';
            }
        }
        }
    });
});
